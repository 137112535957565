<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import helpers from '../../../helpers'
import Journeys from "@/components/dashboard/journeys/dashboard"
import Broadcasts from "@/components/dashboard/broadcasts/dashboard"
import Subscriptors from "@/components/dashboard/subscriptors"

import {
  analyticsMethods,
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    DatePicker,
    Journeys,
    Broadcasts,
    Subscriptors
  },
  data() {
    return {
      title: this.$t('kpi.title'),
      items: [],
      
      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      loader: "spinner",
      color: "#007bff",
      bgColor: "#ffffff",
      height: 128,
      width: 128,
      timeout: 3000, //ms
      lastSyncDate: '-',
      total_items: '-',
      total_interactions: '-',
      total_customers: '-',
      total_sessions: '-',
      conversions: '-',
      personalized_sessions: '-',
      personalized_revenue: '-',
      personalized_revenueRate: '',
      personalized_revenue_last_period: '',
      personalized_conversionRate: '-',
      personalized_rateFromTotal: '',
      personalized_conversion_last_period: '',
      personalized_aov: '-',
      personalized_aovFromTotal: '',
      personalized_aov_last_period: '',
      query: '',
      chartOptions: {
            chart: {
              width: "100%",
              height: 200,
              type: "area",
              sparkline: {
                enabled: true,
              },
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
              width: 1.5,
            },
            fill: {
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [50, 100, 100, 100],
              },
            },
            colors: ["#5e40bf"],
            xaxis: {
              type: 'datetime',
              show: false,
            },
      },
      series: [
        {
          name: "Revenue",
          data: [],
        },
      ],
      seriesConversionRate: [
        {
          name: "Conversion rate",
          data: [],
        },
      ],
      seriesAoV: [
        {
          name: "AOV",
          data: [],
        },
      ],
      user: JSON.parse(localStorage.getItem("auth.currentUser")).user,
      dateFilter: null,
      disabledBefore: new Date().setDate(new Date().getDate() - 90),
      shortcuts: [
        {
          text: this.$t('kpi.filter_date_today'),
          onClick() {
            const start = new Date();
            const end = new Date();
            
            end.setTime(end.getTime() + 0 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_yesterday'),
          onClick() {
            
            const start = new Date();
            const end = new Date();
            
            start.setTime(start.getTime() -1 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_week'),
          onClick() {
            
            var curr = new Date; // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6

            var firstday = new Date(curr.setDate(first));
            var lastday = new Date(curr.setDate(last));
            
            const date = [firstday, lastday];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_month'),
          onClick() {
            var today = new Date; // get current date
            const firstday = new Date(today.getFullYear(), today.getMonth(), 1);
            //const lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const date = [firstday, today];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_week'),
          onClick() {
            const today = new Date();
            const lastWeek = new Date(today);
            lastWeek.setDate(today.getDate() - 7); // Resta 7 días para obtener la semana pasada

            // Obtén el primer día de la semana pasada (domingo)
            const firstDay = new Date(lastWeek);
            firstDay.setDate(lastWeek.getDate() - lastWeek.getDay());

            // Obtén el último día de la semana pasada (sábado)
            const lastDay = new Date(lastWeek);
            lastDay.setDate(lastWeek.getDate() - lastWeek.getDay() + 6);

            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_month'),
          onClick() {
            const today = new Date();
  
            // Calculate the first day of the last month
            const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  
            // Calculate the last day of the last month
            const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_7_days'),
          onClick() {
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 6); // First day is 7 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_30_days'),
          onClick() {
            
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 29); // First day is 30 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
      ],

      isLocal: window.config.local ? true : false,

      reco_impressions: '-',
      reco_ctr: '-',
      reco_cr: '-',
      reco_customers: '-',
      reco_purchases: '-',
      reco_session_purchases: '-',
      reco_directRevenue: '-',
      reco_assistedRevenue: '-',
      executing_journeys:'-',
      active_experiences: '-',
      active_tests: '-',
      personalAnalytics: null,
      purchased_sessions: '-',
      purchase_rate: '-',
      
      seriesJourneys: [
        {
          name: "Revenue",
          data: [],
        },
      ],
      
    };
  },
  mounted() {
    const self = this;

    const today = new Date();
    const lastDay = new Date(today); 
    const firstDay = new Date(today);
    firstDay.setDate(today.getDate() - 29); 
    
    this.dateFilter = [firstDay, lastDay]; 

    this.chartOptions.yaxis = {
        show: false,
        labels: {
          formatter: function (valor) {
            return `$${self.parseMoneyWithLocale(valor)}`;
          }
        }
      }
      this.$refs.revenueChart.updateOptions(this.chartOptions);
  },
  methods:{
    ...analyticsMethods,
    ...helpers,

    getPreviousPersonalizationIndicators (){

      const dates = this.getPreviousPeriod(this.dateFilter[0],this.dateFilter[1]);
      const nextDay = new Date(dates.previousDateTo);
      nextDay.setDate(dates.previousDateTo.getDate() + 1)

      let query = `project=${localStorage.getItem('current_project')}`
          query+= `&dateFrom=${this.formatDate(dates.previousDateFrom)}&dateTo=${this.formatDate(nextDay)}`;  

      this.getPersonalizationIndicators(query).then((res)=>{

        const previousRevenue = res.data.length > 0 ? this.getSummaryByKey('associated_revenue', res.data): 0;
        this.personalized_revenue_last_period = this.calculatePercentageChange(this.personalized_revenue,previousRevenue) ;

      });
    },

    applyFilter(query){

      this.query = query;

      this.getLastSyncDate().then((res)=>{
        if(res && res.data && res.data.last_replication_date){
          this.lastSyncDate = new Date(res.data.last_replication_date).toLocaleDateString() + ' ' + new Date(res.data.last_replication_date).toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'});
        }
      });

      const totalQuery = `project=${localStorage.getItem('current_project')}`
      this.getActiveExperiences(totalQuery).then((res)=>{
        if(res && res.data && res.data.length > 0){
          this.active_experiences = res.data[0].totalTargets;
        }else if(res && res.data && res.data.length == 0){
          this.active_experiences = 0;
        }
      });

      this.getActiveABTests(totalQuery).then((res)=>{
        
        if(res && res.data && res.data.length > 0){
          this.active_tests = res.data[0].totalTargets;
        }else if(res && res.data && res.data.length == 0){
          this.active_tests = 0;
        }
      });

      const params = {q: `where[project]=${localStorage.getItem('current_project')}&where[status]=1`}
      this.getExecutingJourneys(params).then((res)=>{
        this.executing_journeys = res || 0;
       });

      this.getTotalSessionsCount(query).then((res)=>{
        if(res && res.data && res.data.length > 0){
          this.total_sessions = res.data[0].count;
        }else{
          this.total_sessions = 0;
        }
       })

       
      this.getPersonalizationIndicators(query).then((res)=>{
        this.personalAnalytics = res.data;
        
        this.personalized_sessions = res.data.length > 0 ? this.getSummaryByKey('personalized_sessions', this.personalAnalytics): '0';
        this.personalized_revenue = res.data.length > 0 ? this.getSummaryByKey('associated_revenue', this.personalAnalytics): '0';
        const total_aov = res.data.length > 0 ? this.getSummaryByKey('aov', this.personalAnalytics): '0';
        const days = this.personalAnalytics.filter(p=>p.aov > 0).length;
        this.personalized_aov =  days == 0 ? 0 : total_aov/days;
        this.conversions = res.data.length > 0 ? this.getSummaryByKey('conversions', this.personalAnalytics): '0';
        this.purchased_sessions = res.data.length > 0 ? this.getSummaryByKey('purchased_sessions', this.personalAnalytics): 0;
        this.purchase_rate =  this.personalized_sessions == '-' ? `-` : this.personalized_sessions == 0 ? `0%` : `${this.parsePercentage(this.purchased_sessions/this.personalized_sessions * 100)}%`
        this.personalized_conversionRate = this.personalized_sessions == '-' ? `-` : this.personalized_sessions == 0 ? `0%` : `${this.parsePercentage(this.conversions/this.personalized_sessions * 100)}%`

        this.series[0].data = Array.from(this.personalAnalytics, x => [ new Date(x.date).getTime() , x.associated_revenue || 0]);
        this.series[0].data.sort((a, b) => a[0] - b[0]);

        this.$refs.revenueChart.updateSeries(this.series,true);
        this.getPreviousPersonalizationIndicators();

        this.getRecommendationsIndicators(recoQuery).then((res)=>{
          this.reco_session_purchases = this.purchased_sessions == 0 ? `0%` : `${this.parsePercentage(this.getSummaryByKey('session_recommended_purchases', res.data)/ this.purchased_sessions * 100)}%` ;
        });
       });
       
       const recoQuery=`${query}&attributionWindow=7`;

       this.getRecommendationsCustomers(recoQuery).then((resp)=>{
        this.reco_customers = resp.data?.length > 0 ? resp.data[0].count : 0;
      })
       this.getRecommendationsIndicators(recoQuery).then((res)=>{
        this.reco_directRevenue = res.data.length > 0 ? this.getSummaryByKey('direct_revenue', res.data): 0; 
        this.reco_assistedRevenue = res.data.length > 0 ? this.getSummaryByKey('assisted_revenue', res.data): 0; 
        this.reco_impressions = res.data.length > 0 ? this.getSummaryByKey('personalized_sessions', res.data): 0; 
        this.reco_ctr = this.reco_impressions == 0? `0%` :  `${this.parsePercentage( this.getSummaryByKey('ctr', res.data)/this.getSummaryByKey('personalized_sessions', res.data) * 100)}%` ;
        this.reco_cr = this.reco_impressions == 0? `0%` :  `${this.parsePercentage(this.getSummaryByKey('cr', res.data)/this.getSummaryByKey('personalized_sessions', res.data) * 100)}%` ;

        this.reco_purchases = res.data.length > 0 ? this.getSummaryByKey('recommended_purchases', res.data): 0;
        
       });
    },
    
  },
  watch: {
    dateFilter: {
      immediate:true,
      handler(newVal){
        if(newVal){
          const nextDay = new Date(newVal[1]);
          nextDay.setDate(newVal[1].getDate() + 1)

          let query = `project=${localStorage.getItem('current_project')}`
          query+= `&dateFrom=${this.formatDate(newVal[0])}&dateTo=${this.formatDate(nextDay)}`;  

          this.applyFilter(query)
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row mb-4">
      <div class="col-lg-12 mb-3">
        <div class="d-flex align-items-center">
          <div class="ms-3 flex-grow-1 mb-0">
            <h5 class="mb-0 card-title" style="font-size: 17px;">{{$t('kpi.hello_title').replace('##', user.name)}}</h5>
            <p class="text-muted mb-0">{{$t('kpi.hello_subtitle')}}</p>
            <a class="small text-primary mb-0" href="https://academy.gopersonal.ai/guia-de-usuario/dashboard" target="_blank">{{ $t('common.visit_academy') }} <i class="bx bx-link-external"></i></a>
          </div>
          <div class="text-end w-25">
            <date-picker v-model="dateFilter" :shortcuts="shortcuts" placeholder="Selecioná la fecha" range :format="'DD.MM.YYYY'" :clearable="false" :disabled-date="(date) => date >= new Date() || date < new Date().setDate((new Date()).getDate() - 90)">
            </date-picker>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3 text-center">
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex" >
                      <i data-v-39fea53d="" class="bx bx-crosshair font-size-18 me-1"></i> {{$t('kpi.sessions')}} <i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('kpi.sessions_tooltip')"/>
                    </p>
                    <h3 class="mb-0" v-b-popover.hover.bottom="total_sessions">{{ parseCountReduced(total_sessions) }}</h3>
                  </div>
                </div>
              <div class="col-sm-3 text-center" style="border-right-style: solid; border-right-color: lightgray;">
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex">
                      <i data-v-39fea53d="" class="bx bx-target-lock font-size-18 me-1"></i> {{$t('kpi.sessions_personalized')}} <i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('kpi.sessions_personalized_tooltip')"/>
                    </p>
                    <h3 class="mb-0" v-b-popover.hover.bottom="personalized_sessions">{{ parseCountReduced(personalized_sessions) }}</h3>
                  </div>
                </div>
                <div class="col-sm-2 text-center" >
                  <div>
                      <p class="text-muted text-truncate mb-0 d-inline-flex">
                        <i data-v-39fea53d="" class="bx bxs-magic-wand font-size-18 me-1"></i> {{$t('kpi.active_experiences')}} <i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('kpi.active_experiences_tooltip')"/>
                      </p>
                      <h3 class="mb-0">{{ active_experiences }}</h3>
                    </div>
                </div>
                <div class="col-sm-2 text-center">
                 <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex">
                      <i data-v-39fea53d="" class="bx bxs-flask font-size-18 me-1"></i> {{$t('kpi.active_tests')}} <i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('kpi.active_abtests_tooltip')"/>
                    </p>
                    <h3 class="mb-0">{{active_tests}}</h3>
                  </div>
                </div>
              <div class="col-sm-2 text-center">
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex">
                      <i data-v-39fea53d="" class="bx bx-directions font-size-18 me-1"></i> Journeys <i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.left="$t('kpi.active_journeys_tooltip')"/>
                    </p>
                    <h3 class="mb-0">{{executing_journeys}}</h3>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card">
          <i class="mdi mdi-help-circle-outline font-size-18 top-right-menu text-primary" v-b-popover.hover.bottom="$t('kpi.revenue_tooltip')"></i>
          <div class="card-body">
            <div class="row">
              <h4 class="card-title mb-4">{{$t('personalizations.title')}}</h4>
              <div class="col-4 text-muted">
                <div class="mb-4">
                  <p class="mb-2">Revenue</p>
                  <h4 v-b-popover.hover.bottom="`$${parseMoneyWithLocale(personalized_revenue)}`">{{ `$${parseMoneyReducedWithLocale(personalized_revenue)}` }}</h4>
                    <div class="mb-2"><span class="badge badge-soft-success font-size-12 me-1" :class="{
                        'badge-soft-warning': personalized_revenue_last_period === 0,
                        'badge-soft-danger': personalized_revenue_last_period < 0,
                        'badge-soft-success': personalized_revenue_last_period > 0,
                        }"> {{ `${personalized_revenue_last_period > 0 ? '+' : ''}${personalized_revenue_last_period}%` }}</span> {{$t('kpi.from_last_period')}}
                      </div>
                      <span class="badge badge-soft-primary font-size-12 me-1 mb-1" >{{ `${conversions} ${$t('kpi.conversions')}`}}</span>
                      <span class="badge badge-soft-primary font-size-12 me-1 mb-1" >{{ `${personalized_conversionRate} ${$t('kpi.conversion_rate_rate')}`}}</span>
                      <span class="badge badge-soft-primary font-size-12 me-1 mb-1" v-b-popover.hover.bottom="`$${parseMoneyWithLocale(personalized_aov)}`">{{ `$${parseMoneyReducedWithLocale(personalized_aov)} AOV`}}</span>
                      <!--<span class="badge badge-soft-primary font-size-12 me-1 mb-1">{{ `${purchase_rate} ${$t('kpi.purchase_rate')}`}}</span>-->
                      
                      <div class="text-start pt-4">
                        <a href="/personal-analyze" class="btn btn-primary btn-sm">{{ $t('kpi.view_more') }} <i class="mdi mdi-chevron-right ml-1"></i>
                      </a>
              </div>
                </div>
                </div>
              <div class="col-8">
                <div class="flex-shrink-0 align-self-center">
                  <apexchart ref="revenueChart" class="apex-charts" :options="chartOptions"  width="100%" height="200" dir="ltr" :series="series.length > 0 ? series : []" ></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 d-flex">
        <div class="card">
          <i class="mdi mdi-help-circle-outline font-size-18 top-right-menu text-primary" v-b-popover.hover.left="$t('kpi.reco_tooltip')"></i>
          <div class="card-body pb-0">
            <div class="row h-100">
              <h4 class="card-title mb-2">{{$t('kpi.recommendations')}}</h4>
              <div class=" col-6 text-muted text-center">
                <p class="mb-2">{{$t('kpi.recommendations_direct_revenue')}}</p>
                <h4 v-b-popover.hover.bottom="`$${parseMoneyWithLocale(reco_directRevenue)}`">{{ `$${parseMoneyReducedWithLocale(reco_directRevenue)}` }}</h4>
              </div>
              <div class=" col-6 text-muted text-center">
                <p class="mb-2">{{$t('kpi.recommendations_assisted_revenue')}}</p>
                <h4 v-b-popover.hover.bottom="`$${parseMoneyWithLocale(reco_assistedRevenue)}`">{{ `$${parseMoneyReducedWithLocale(reco_assistedRevenue)}` }}</h4>
              </div>
              <hr class="mb-0"/>
              <div class="text-center text-muted">
                <div class="row h-100">
                  <div class="col-4">
                    <div class="mt-0">
                      <p class="mb-1 text-truncate">{{$t('reco_analyze.sessions')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('reco_analyze.sessions_tooltip')"/></p>
                      <h5 class="mb-0" v-b-popover.hover.bottom="reco_impressions">{{parseCountReduced(reco_impressions)}}</h5>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="mt-0">
                      <p class="mb-1 text-truncate">CTR<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('reco_analyze.ctr_tooltip')"/></p>
                      <h5 class="mb-0">{{reco_ctr }}</h5>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="mt-0">
                      <p class="mb-1 text-truncate">CR<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.left="$t('reco_analyze.cr_tooltip')"/> </p>
                      <h5 class="mb-0">{{reco_cr}}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mb-0"/>
              <div>
                <p style="font-size: 11px;" class="mb-0" v-html="`<i class='bx bxs-user fw-bold'></i> <strong>${parseCountReduced(reco_customers)}</strong> ${$t('kpi.recommendations_customers')}`"></p>
                <p style="font-size: 11px;" class="mb-0" v-html="`<i class='bx bxs-shopping-bag fw-bold'></i> <strong>${parseCountReduced(reco_purchases)}</strong> ${$t('kpi.recommendations_purchases')}`"></p>
                <p style="font-size: 11px;" class="mb-0" v-html="`<i class='bx bx-target-lock fw-bold'></i> <strong>${reco_session_purchases}</strong> ${$t('kpi.recommendations_sessions_purchases')}`"></p>
              </div>
              <div class="text-center">
                <a href="/reco-analyze" class="btn btn-primary btn-sm"> {{ $t('kpi.view_more') }}  <i class="mdi mdi-chevron-right ml-1"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 d-flex" v-if="isLocal">
        <Subscriptors/>
      </div>
      <div class="col-lg-4 d-flex">
        <Journeys :dateFilter="dateFilter"/>
      </div>
      <div class="col-lg-4 d-flex">
        <Broadcasts :dateFilter="dateFilter"/>
      </div>
      <div class="col-lg-4 d-flex" v-if="isLocal">
        <div class="card w-100">
          <i class="mdi mdi-help-circle-outline font-size-18 top-right-menu text-primary" v-b-popover.hover.left="$t('kpi.reco_tooltip')"></i>
          <div class="card-body pb-0">
            <div class="row h-100">
              <h4 class="card-title mb-2">{{'Search'}}</h4>
            </div> 
          </div>
        </div>
      </div>
    </div>
      
</Layout>
</template>

<style scoped>
 .top-right-menu {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
}

</style>

<style>
.mx-btn-shortcut{
  font-size: 13px !important;
  font-family: var(--bs-body-font-family);
  padding: 0 4px;
  color: var(--bs-primary)
}

.mx-datepicker-sidebar + .mx-datepicker-content {
    margin-left: 150px !important;
    border-left: 1px solid #e8e8e8;
}

.mx-datepicker-sidebar {
  width: 120px;
}

.cell.active{
  background-color: var(--bs-primary) !important;
}
</style>
