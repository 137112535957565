<script>

import {} from "@/state/helpers";

	export default {
		data() {
			return {
				
		};
	},
	watch: {
		
	},
	mounted(){
		
	},
	methods: {
		
		
	},
	props: {
		
	},
}	
</script>

	<template>
	<div class="card w-100">
    <i class="mdi mdi-help-circle-outline font-size-18 top-right-menu text-primary" v-b-popover.hover.left="$t('kpi.subscriptors_tooltip')"></i>
		<div class="card-body">
			<div class="row">
        <h4 class="card-title mb-4">{{$t('kpi.subscriptors_title')}}</h4>
        <div class="col text-center">
          <p class="text-muted text-truncate mb-0 d-inline-flex" >
            
              <img
                src="@/assets/images/brands/email.png"
                alt=""
                class="avatar-sm h-auto d-block rounded me-1"
                style="width: 1.3rem !important;"
              />
              <label class="m-auto text-muted">Email</label>
          </p>
          <h3 class="mb-0" v-b-popover.hover.bottom="100123">10.1K</h3>
        </div>
        <div class="col text-center">
          <p class="text-muted text-truncate mb-0 d-inline-flex" >
            <img
              src="@/assets/images/brands/email.png"
              alt=""
              class="avatar-sm h-auto d-block rounded me-1"
              style="width: 1.3rem !important;"
            />
            <label class="m-auto text-muted">Web Push</label>
        </p>
          <h3 class="mb-0" v-b-popover.hover.bottom="100123">10.1K</h3>
        </div>
        <div class="col text-center">
          <p class="text-muted text-truncate mb-0 d-inline-flex" >
            <i class="bx bx-crosshair font-size-18 me-1"></i> SMS
          </p>
          <h3 class="mb-0" v-b-popover.hover.bottom="100123">10.1K</h3>
        </div>
        <div class="col text-center">
          <p class="text-muted text-truncate mb-0 d-inline-flex" >
            <i class="bx bx-crosshair font-size-18 me-1"></i> App Push
          </p>
          <h3 class="mb-0" v-b-popover.hover.bottom="100123">10.1K</h3>
        </div>
        <div class="col text-center">
          <p class="text-muted text-truncate mb-0 d-inline-flex" >
            <i class="bx bx-crosshair font-size-18 me-1"></i> WhatsApp
          </p>
          <h3 class="mb-0" v-b-popover.hover.bottom="100123">10.1K</h3>
        </div>
      </div>
    </div>
	</div>
</template>
